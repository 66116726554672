<template>
  <div class="hint">
    <XIcon class="x-icon" @click="pushBack" />
    <h1>Here's a Hint!</h1>
    <hr class="separator" />
    <p class="paragraph">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque viverra ultricies justo eu vehicula.
      Pellentesque sit amet eros id diam volutpat sagittis feugiat id nulla. Nam sollicitudin, mauris aliquet interdum
      efficitur, magna nunc mattis turpis, non porttitor orci ante vitae ipsum.
    </p>
  </div>
</template>

<script>
import XIcon from '@/assets/icons/x_icon.svg';

export default {
  name: 'Hint',
  components: {
    XIcon,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    pushBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.hint {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../assets/images/bg_main_repeating@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.paragraph {
  width: 80%;
  font-size: 18px;
  text-align: left;
}
.x-icon {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 4em;
  height: 1.2em;
  margin-top: 1em;
}
.separator {
  width: 6em;
}
</style>
